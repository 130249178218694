import fetch from 'globals/wa-fetch';
import type { ZendeskTokenResponse } from 'app/state/menu/types';
import type { Action, Dispatch, ThunkedAction } from 'globals/types';
import { showToast } from 'toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';
import { getUser, isSharedAccount } from 'stores/user-store';
import { getStoredSelectedEnvironment } from 'react-components/env-controller/env-controller-context';
import { Localisation, LocalisationTypes } from '@workivate/tho-web-shared';
import Language from 'language';

export function initialiseCompany(isCompanyInitialised: boolean): Action {
  return { type: 'GLOBAL/IS_COMPANY_INITIALISED', isCompanyInitialised };
}

export function setIsSectionsMenuOnScreen(
  isSectionsMenuOnScreen: boolean,
): Action {
  return { type: 'GLOBAL/IS_SECTIONS_MENU_ON_SCREEN', isSectionsMenuOnScreen };
}

export function getZendeskToken(userID: string): Promise<ZendeskTokenResponse> {
  return fetch(`/users/${userID}/jwt/zendesk`, {
    version: 1.4,
    token: true,
    method: 'post',
  });
}

// Type guard function to check if a string is one of the allowed values
// Define the allowed values as an array
const allowedHelpPathKeys: LocalisationTypes.HelpPathKeys[] = Object.keys(
  Localisation.helpPaths,
) as LocalisationTypes.HelpPathKeys[];

export function isHelpPathKey(
  key: string,
): key is LocalisationTypes.HelpPathKeys {
  return allowedHelpPathKeys.includes(key as LocalisationTypes.HelpPathKeys);
}

// 'test' should point to a sandbox zendesk account
// accessible by Test users
// ZenDesk links with authentication will never work for Integration users
export const getZendeskEnvironment = (): 'live' | 'test' => {
  if (IS_PRODUCTION) return 'live';

  // environment picker is used on Integration and localhost only
  if (getStoredSelectedEnvironment() === 'live') return 'live';

  return 'test';
};

// public ZenDesk links without authentication
export const getZendeskLink = ({
  key,
}: {
  key: LocalisationTypes.HelpPathKeys;
}) =>
  Localisation.getHelpLink({
    key,
    locale: Language.getLocale(),
    environment: 'live',
  });

type GetZendeskGoToLinkType = {
  zendeskLinkOrKey: string | undefined;
  zendeskLinksBasePath: string;
  locale: string;
};

export const getZendeskGoToLink = ({
  zendeskLinkOrKey,
  zendeskLinksBasePath,
  locale,
}: GetZendeskGoToLinkType): string | null => {
  if (!zendeskLinkOrKey) {
    return null;
  }

  // if this is a full zendesk link, translate it. This will be a live environment link.
  if (zendeskLinkOrKey.includes('https')) {
    return Localisation.translateHelpLink({
      helpLink: zendeskLinkOrKey,
      locale,
    });
  }

  // if this isn't a URL then it's a key for the Localisation helper utility
  // construct and translate the full zendesk link for the current environment.
  if (isHelpPathKey(zendeskLinkOrKey)) {
    return Localisation.getHelpLink({
      key: zendeskLinkOrKey,
      locale,
      environment: getZendeskEnvironment(),
    });
  }

  return `${zendeskLinksBasePath}.${zendeskLinkOrKey}`;
};

// authenticate with jwt to view protected ZenDesk content
export function openZendeskLinkWithToken(
  zendeskLinkOrKey?: string,
  openInSameWindowTab = false,
): ThunkedAction {
  const zendeskLinksBasePath = `zendesk.${getZendeskEnvironment()}`;

  const zendeskGoToLink = getZendeskGoToLink({
    zendeskLinkOrKey,
    zendeskLinksBasePath,
    locale: Language.getLocale(),
  });

  return function (dispatch: Dispatch) {
    if (isSharedAccount() && zendeskGoToLink) {
      return window.open(zendeskGoToLink, '_blank', 'noopener noreferrer');
    } else if (isSharedAccount()) {
      return window.open(
        getZendeskLink({ key: 'help_centre' }),
        '_blank',
        'noopener noreferrer',
      );
    }

    const redirection = openInSameWindowTab
      ? window
      : window.open(
          `${window.location.protocol}//${window.location.host}/waiting`,
          '_blank',
        );

    return getZendeskToken(getUser()['user_id'])
      .then((response: ZendeskTokenResponse) => {
        const zendeskToken = response.body[0].token;
        const helpCentre = Localisation.getHelpLoginLink({
          environment: getZendeskEnvironment(),
          zendeskToken,
        });
        const returnTo = zendeskGoToLink ? `&return_to=${zendeskGoToLink}` : '';

        if (redirection) {
          redirection.location = `${helpCentre}${returnTo}`;
        }
      })
      .catch(error => {
        redirection?.close();

        // if the error is 401 then isLoggingOut will be set to true
        // and the toast may not show
        dispatch(
          showToast({
            type: ToastType.ERROR,
            message: polyglot.t('global.error'),
          }),
        );

        // allow the calling function to handle the error
        return Promise.reject(error as Error);
      });
  };
}
