import queryString from 'query-string';
import fetch from 'globals/wa-fetch';
import { getUser } from 'stores/user-store';
import type { User } from './types';
import { getRootHost } from 'globals/helpers';
import { getStore } from 'app/hermes-redux';
import { setCookie, getCookie } from 'globals/cookieManager';
import { getSuffixFromHost, getSuffixFromHref } from 'router/navigation';
// Legacy logins function - To refactor properly
import getErrorMessage from 'constants/error-messages';
import isEmpty from 'lodash/isEmpty';

import * as Storage from 'globals/storage';
import Language from 'language';
import { getStoredSelectedEnvironment } from 'react-components/env-controller/env-controller-context';

// TODO: remove depended when API is updated - 2018-02-28
export const isDependantAccount = (user: User) =>
  user['account_type'] === 'depended' || user['account_type'] === 'dependant';

export const isLimitedAccount = (user: User) =>
  user['account_type'] === 'limited';

export const getUserNotificationEmailAddress = (user: User) =>
  user[user['notification_email_field']];

const WAM_SUBDOMAIN_COOKIE = 'WAM_SUBDOMAIN';

export const setSubDomainCookie = subdomain => {
  const in1year = 365 * 24 * 60 * 60;
  const domain = '.' + getRootHost();
  setCookie(WAM_SUBDOMAIN_COOKIE, subdomain, in1year, domain);
};

export const getSubdomainCookie = () => {
  return getCookie(WAM_SUBDOMAIN_COOKIE) || null;
};

export const handleSubdomainRedirect = async () => {
  const subdomain = getSubdomainCookie();
  const host = window.location.host; // Host gives port number also. hostname does not
  const splitHost = host.split('.');
  if (subdomain && splitHost[0] === 'app') {
    splitHost.shift();
    window.location.href =
      '//' +
      subdomain +
      '.' +
      splitHost.join('.') +
      window.location.pathname +
      window.location.search;
  }
};

export const handleLoginRedirect = (
  companySubdomain: string,
  isPostSignup = false,
  path?: string,
) => {
  // After signup we want to redirect without any url path otherwise we'll end up back
  // on the signup page rather than logging in.
  const suffix = isPostSignup ? getSuffixFromHost() : getSuffixFromHref();
  const domain = '.' + getRootHost();
  const tokenKey = Storage.getKeys().token;
  const token = getToken() || getSignupSession();
  const persistence = Storage.getPersistence();
  const authData = { [tokenKey]: token };
  const msTeamsUrl = getMsTeamsUrl();
  const storedEnvironment = getStoredSelectedEnvironment();

  setCookie('WAM_AUTH', JSON.stringify(authData), 5, domain);
  setSubDomainCookie(companySubdomain);

  // Clean current data
  clearLogoutStorage();

  const hasQueryParams = /[?&]/.test(suffix);

  const queryParams: string[] = [];

  if (persistence) {
    queryParams.push(`persistence=${persistence}`);
  }

  if (Language.getLoginLanguagePersistence()) {
    queryParams.push(`lang=${Language.getLocale()}`);
  }

  if (msTeamsUrl) {
    queryParams.push(`msTeamsReturnUrl=${msTeamsUrl}`);
  }

  if (storedEnvironment) {
    queryParams.push(`setEnvironment=${storedEnvironment}`);
  }

  // Client-side redirect to the correct subdomain.
  window.location.href = `//${companySubdomain}${suffix}${path || ''}${
    hasQueryParams ? '&' : '?'
  }${queryParams.join('&')}`;
};

export const replaceWamWithOneTimeToken = async () => {
  const userId = getUser()['user_id'];

  const redirectionSecondsLimit = 30;

  const expirationDate = new Date();

  expirationDate.setSeconds(
    expirationDate.getSeconds() + redirectionSecondsLimit,
  );

  const expirationTimestamp = parseInt(
    (expirationDate.getTime() / 1000).toFixed(0),
  );

  const { body: wamToOttResponse } = await fetch(
    `/users/${userId}/wam-to-ott`,
    {
      method: 'post',
      version: 1.3,
      headers: {
        'Wam-Token': getToken(),
      },
      body: {
        expiration_date: expirationTimestamp,
      },
    },
  );

  const { token: oneTimeToken } = wamToOttResponse || {};

  return oneTimeToken;
};

export const getLoginErrorMessage = (result: any) => {
  const code = result?.error?.code;

  if (!code) {
    return polyglot.t('api_errors.something_wrong');
  }

  return code === -4 && !isEmpty(result.error.fields.email)
    ? getErrorMessage(-1041)
    : getErrorMessage(code);
};

// TO USE FOR NON REACT SECTION - TO REMOVE AFTER REFACTOR
export const isLoggedIn = () => getStore().getState().session.user !== null;
export const getToken = (): string | null =>
  Storage.get(Storage.getKeys().token);
export const getSignupSession = () =>
  Storage.get(Storage.getKeys().signupSession);
export const setToken = (token: string) =>
  token && Storage.set(Storage.getKeys().token, token);
// SSO HELPERS
export const getSSOConfig = () => {
  const { company } = getStore().getState();
  return company.sso || {};
};

export const isSSO = () => getUser()['is_sso_login'];

export const getSSOLoginLink = (
  locale: string,
  returnUrl: string,
  redirectUrl?: string,
) => {
  const sso = getSSOConfig();

  if (!isEmpty(sso)) {
    const translatedUrl = (sso['translated_login_urls'] || {})[locale];
    const url = translatedUrl || sso['authentication_entry_point_url'];
    const hasQueryParams = !!url?.split('?')[1];
    const relayUrl = `${returnUrl}${
      redirectUrl
        ? `%26${queryString.stringify({
            return_to: redirectUrl,
          })}`
        : ''
    }`;

    return `${url}${hasQueryParams ? '&' : '?'}RelayState=${relayUrl}`;
  }
};

export const getSSOloginNoticeImageUrl = () => {
  const sso = getSSOConfig();

  if (!isEmpty(sso)) {
    const urlObj = sso['login_notice_image_url'];

    return !!urlObj && urlObj;
  }
};

export const getSSOLoginSystemDisplayName = () => {
  const sso = getSSOConfig();

  if (!isEmpty(sso)) {
    return sso['login_system_display_name'] || '';
  }
};

export const getSSOLogoutLink = (locale: string) => {
  const sso = getSSOConfig();
  const translatedUrl = (sso['translated_logout_urls'] || {})[locale];

  return translatedUrl || sso['authentication_exit_point_url'];
};

export const setSSOToken = (token: string) =>
  token && Storage.set(Storage.getKeys().SSOToken, token);
export const getSSOToken = (): string | null =>
  Storage.get(Storage.getKeys().SSOToken);

export const setMsTeamsUrl = (url: string) => {
  return url && Storage.set(Storage.getKeys().msTeamsReturnUrl, url, false);
};

export const getMsTeamsUrl = () => {
  return Storage.get<string>(Storage.getKeys().msTeamsReturnUrl, false);
};

export const setIsFromTeams = (isFromTeams: boolean) => {
  return (
    isFromTeams &&
    Storage.set(Storage.getKeys().isFromTeams, isFromTeams, false)
  );
};

export const getIsFromTeams = () => {
  return Storage.get(Storage.getKeys().isFromTeams, false);
};

export const clearLogoutStorage = () => {
  // cleaning up the storage
  Storage.remove(Storage.getKeys().token);
  Storage.remove(Storage.getKeys().signupSession);
  Storage.remove(Storage.getKeys().signupResponse);
  Storage.remove(Storage.getKeys().signupCompany);
  Storage.remove(Storage.getKeys().SSOToken);
  Storage.remove(Storage.getKeys().persistence);
  Storage.remove(Storage.getKeys().pensionBenefitsDontShowProceedMessage);
  Storage.remove(Storage.getKeys().testableFeaturesEnabled);
  Storage.remove(Storage.getKeys().isProfileEditDisclaimerTooltipDismissed);
  Storage.remove(Storage.getKeys().retailerRedirectPayload);
  Storage.remove(Storage.getKeys().useAssessmentsGlobalContent);
  Storage.remove(Storage.getKeys().isCreatingLimitedAccount);
  Storage.remove(Storage.getKeys().tokenExpiryTime);
  Storage.remove(Storage.getKeys().isFromTeams);
  Storage.remove(Storage.getKeys().tenancyHash);
  Storage.remove(Storage.getKeys().appointmentContactDetails);
};
