import * as cookieManager from 'globals/cookieManager';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { getSubdomainFromHost } from 'router/navigation';
import { getRootHost } from 'globals/helpers';
import * as Session from 'state/session/helpers';
import * as Storage from 'globals/storage';
import { initHermes } from './run';
import { initialiseDataDog } from './services/datadog/datadog-helpers';
import {
  performTenancyLookup,
  getRegionalApiEndpoints,
  lookupKeys,
} from 'globals/regional-api';
import {
  removeBlackListedPrams,
  injectGoogleTagManager,
  BLACKLISTED_PARAMS,
} from 'wa-storybook/helpers/google-analytics/apps/common/helpers';
import {
  testableFeaturesEnabled,
  isUnifiedLoginEnabled,
} from 'globals/testable';
import { SIGNUP_CODES } from './pages/signup/constants';
import {
  errorCodes,
  parseErrorCode,
} from 'wa-storybook/global/constants/error-messages';
import waFetch from 'globals/wa-fetch';
import {
  getStoredSelectedEnvironment,
  setAPIEndpoints,
} from 'react-components/env-controller/env-controller-context';
import {
  AppLogger,
  AuthConstants,
  AuthClient,
  Analytics,
} from '@workivate/tho-web-shared';
import { registerServiceWorker } from 'register-sw';

const urlParams = queryString.parse(location.search, {
  parseBooleans: true,
});

AppLogger.configure({ isProduction: IS_PRODUCTION });

AuthClient.configure({
  authEndpoint: `https://${window.WAM.ENV.awsCognitoSSODomain}`,
});
const currentDomain = '.' + getRootHost();
const hasUrlParams = !isEmpty(urlParams);
const msTeamsUrl = urlParams['msTeamsReturnUrl'];
const isFromTeams = urlParams['isFromTeams'];
const tenancyHashParam: string = urlParams['tenancy_hash'];
const uslOTT: string = urlParams['x-usl-ott'];

const auth = JSON.parse(cookieManager.getCookie('WAM_AUTH') as string);
const persistence = Boolean(
  (hasUrlParams && urlParams['persistence']) || Storage.getPersistence(),
);

const userToken =
  (hasUrlParams && urlParams['userToken']) || (!isEmpty(auth) && auth.wamToken);

registerServiceWorker();

if (isUnifiedLoginEnabled()) {
  if (uslOTT) {
    await AuthClient.register('web', true);
    const authResponse = await AuthClient.authorizeWithOTT(uslOTT);

    const { tenancy_hash } = authResponse;
    if (tenancy_hash) {
      localStorage.setItem(
        lookupKeys.tenancyHash,
        JSON.stringify(tenancy_hash),
      );
    }
  }

  if (tenancyHashParam) {
    // Attempt to parse the tenancyHash and check that it's an array of strings
    try {
      const tenancyHashArray: string[] = JSON.parse(
        tenancyHashParam.replace(/'/g, '"'),
      );
      if (
        !Array.isArray(tenancyHashArray) ||
        !tenancyHashArray.every(element => typeof element === 'string')
      ) {
        throw new Error('tenancyHash must be an array of strings');
      }
      localStorage.setItem(
        lookupKeys.tenancyHash,
        JSON.stringify(tenancyHashArray),
      );
    } catch (error) {
      AppLogger.error('Failed to parse (incorrect format) tenancyHash:', error);
      throw error;
    }
  }
}

/*
 * We need to logout the email-less SSO user before using a new token
 * LT-6894
 */
const token = hasUrlParams && urlParams['token'];
if (token) {
  Session.clearLogoutStorage();
}

/**
 * Clear Oauth2 related data to enable ghost login if using a one time token
 */

if (userToken || token) {
  // delete Oauth2 login related data to continue with ghost login
  AuthClient.signOut();
  localStorage.setItem(
    Storage.storageKeys.authenticationMethod,
    AuthConstants.AUTH_METHODS.wamToken,
  );
}

/* We need to set back the token and the timestamp after we clear the previous
 * ones, because we need to read them deeper in the app, and we want to avoid
 * having user tokens as url parameters.
 */
Storage.setPersistence(persistence);
Session.setSSOToken(token as string);
Session.setMsTeamsUrl(msTeamsUrl as string);
Session.setIsFromTeams(!!isFromTeams);

/**
 * Default debugLogsOn to true in non-production environments and if it's not set
 */
if (
  !IS_PRODUCTION &&
  localStorage.getItem(Storage.storageKeys.debugLogsOn) === null
) {
  localStorage.setItem(Storage.storageKeys.debugLogsOn, 'true');
}

/*
Data dog init
*/
if (
  IS_PRODUCTION ||
  (!IS_PRODUCTION &&
    localStorage.getItem(Storage.storageKeys.dataDogEnabled) === 'true')
) {
  initialiseDataDog();
}

/*
 We need to remove url parameters early as possible to avoid leaking the tokens.
 ie: Analytics
 */
if (!testableFeaturesEnabled()) {
  removeBlackListedPrams();

  window[`ga-disable-${window.WAM.ENV.analyticsMeasurementId}`] = true;

  /*
   * Only inject GA after we remove the blacklisted url params to avoid sending them.
   */
  injectGoogleTagManager(window.WAM.ENV.analyticsMeasurementId);
} else {
  // Use of the tho-web-shared Analytics is behind Testable
  // TODO test and enable
  Analytics.removeForbiddenParameters({
    forbiddenParameters: BLACKLISTED_PARAMS,
  });

  window[`ga-disable-${window.WAM.ENV.analyticsMeasurementId}`] = true;

  /*
   * Only inject GA after we remove the forbidden url params to avoid sending them.
   */
  Analytics.injectGoogleTagManager({
    measurementId: window.WAM.ENV.analyticsMeasurementId,
    forbiddenParameters: BLACKLISTED_PARAMS,
  });
}

require('./app.scss');

// We do a Call to the API directly because if there's a token, it comes from the API Auth system
export const loginFromToken = (wamToken, domain = '.' + getRootHost()) => {
  const onAuthResponse = ({ body: authMobileResponse, error }) => {
    if (
      error &&
      error.code === parseErrorCode(errorCodes['session_timeout'][0])
    ) {
      return Promise.reject(error);
    }

    const {
      token,
      user,
      company, // TODO: Company data from auth/mobile endpoint is incomplete so it is not used anymore, a new fetch it is in place
      mfa,
      signup_state: signupState,
    } = authMobileResponse || {};

    if (signupState === SIGNUP_CODES.ADMIN_CONFIRMATION_REQUIRED) {
      initHermes();

      return;
    }

    if (mfa) {
      Storage.setPersistence(true);
    }

    Session.setToken(token);

    Storage.set(Storage.getKeys().tokenExpiryTime, user['token_expiry_time']);

    // TODO: This is the only place where company data from auth is used, we can get rid of it
    // as we already make a separate get for company data, solution: remove company data from endpoint and keep just 'wa_subdomain'
    // when starting MFA setup flow company is not returned
    const companySubdomain = !mfa && company['wa_subdomain'];

    // If not correct subdomain and not starting MFA setup flow - redirect
    if (!mfa && (!companySubdomain || subdomain !== companySubdomain)) {
      Session.handleLoginRedirect(companySubdomain);
    } else {
      cookieManager.deleteCookie('WAM_AUTH', domain);

      initHermes({ user, mfa });
      return;
    }
  };

  // Used when:
  // - natives open the app with a token (wamToken),
  // - during login when redirected from different subdomain's login page (wamToken),
  // - hwr user logs in (wamToken),
  // - admin clicks on 'visit network' link (ott),

  const {
    subdomain: queryStringSubdomain,
    setEnvironment: setEnvFromQueryString,
  } = queryString.parse(location.search);

  const setEnvironment =
    setEnvFromQueryString ?? getStoredSelectedEnvironment() ?? null;
  const subdomain = getSubdomainFromHost();

  if (setEnvironment && (!IS_PRODUCTION || !IS_TEST)) {
    setAPIEndpoints(setEnvironment);
  }

  return performTenancyLookup(
    lookupKeys.domain,
    (queryStringSubdomain as string) || subdomain,
  )
    .then(() => {
      const headers = {
        Accept: 'application/vnd.wam-api-v1.3+json',
        'Content-Type': 'application/json',
      };

      if (urlParams['ott']) {
        headers['One-Time-Token'] = wamToken;
      } else if (wamToken) {
        headers['Wam-Token'] = wamToken;
      }

      return waFetch(
        `${getRegionalApiEndpoints().waapi_root}/auth/mobile`,
        {
          method: 'post',
          headers,
        },
        undefined,
        true,
        false,
      );
    })
    .then(onAuthResponse)
    .catch(res => {
      if (res.body?.mfa) {
        onAuthResponse(res);
      }
    });
};

if (userToken) {
  loginFromToken(userToken, currentDomain);
} else {
  initHermes();
}
