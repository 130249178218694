import {
  __,
  complement,
  compose,
  concat,
  includes,
  curry,
  join,
  map,
  toPairs,
  useWith as ramdaUseWith,
  when,
} from 'ramda';
import { generatePath } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Action } from './types';
import { getUser } from 'stores/user-store';
import { getCompany } from '../stores/company-store';
import Language from 'language';
import cloudinary from 'globals/cloudinary';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _clone from 'lodash/clone';
import each from 'lodash/each';
import _isUndefined from 'lodash/isUndefined';
import _isNull from 'lodash/isNull';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { getToken, replaceWamWithOneTimeToken } from 'state/session/helpers';
import { getStore } from 'app/hermes-redux';
import {
  sessionInvalidationErrorCodes,
  sessionInvalidationTypes,
} from 'wa-storybook/components/modal/session-invalidation-alert-modal/constants';
import type { SessionInvalidationTypes } from 'wa-storybook/components/modal/session-invalidation-alert-modal/types';
import { logout, setIsLoggingOut } from 'state/session/actions';
import { showToast } from '../react-components/toaster-comp/state/actions';
import { ToastType } from 'react-components/toaster-comp/state/types';
import {
  errorCodes,
  parseErrorCode,
} from 'wa-storybook/global/constants/error-messages';

export const partition = (tab, range) =>
  tab.reduce((prev, item, index) => {
    if (Array.isArray(prev[Math.floor(index / range)])) {
      prev[Math.floor(index / range)].push(item);
    } else {
      prev.push([item]);
    }
    return prev;
  }, []);

export const isEqual = (firstItem, secondItem) =>
  _isEqual(firstItem, secondItem);
export const cloneDeep = item => _cloneDeep(item);

export const padZero = (text, totalLength?) => {
  totalLength = totalLength || 2;
  const zeros = new Array(totalLength).join('0');

  return (zeros + text).slice(-totalLength);
};

export const invertColor = (hexColor, isBlackWhite) => {
  let innerHexColor = hexColor;

  if (innerHexColor.indexOf('#') === 0) {
    innerHexColor = innerHexColor.slice(1);
  }

  // convert 3-digit hex to 6-digits.
  if (innerHexColor.length === 3) {
    innerHexColor =
      innerHexColor[0] +
      innerHexColor[0] +
      innerHexColor[1] +
      innerHexColor[1] +
      innerHexColor[2] +
      innerHexColor[2];
  }

  if (innerHexColor.length !== 6) {
    // return white as default colour.
    return '#FFFFFF';
  }

  let red: number | string = parseInt(innerHexColor.slice(0, 2), 16),
    green: number | string = parseInt(innerHexColor.slice(2, 4), 16),
    blue: number | string = parseInt(innerHexColor.slice(4, 6), 16);

  if (isBlackWhite) {
    // http://stackoverflow.com/a/3943023/112731
    red /= 255.0;
    green /= 255.0;
    blue /= 255.0;

    red = red <= 0.03928 ? red / 12.92 : ((red + 0.055) / 1.055) ^ 2.4;
    green = green <= 0.03928 ? green / 12.92 : ((green + 0.055) / 1.055) ^ 2.4;
    blue = blue <= 0.03928 ? blue / 12.92 : ((blue + 0.055) / 1.055) ^ 2.4;

    const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

    return luminance > 0.179 ? '#2C2E30' : '#FFFFFF';
  }

  // invert color components
  red = (255 - red).toString(16);
  green = (255 - green).toString(16);
  blue = (255 - blue).toString(16);

  // pad each with zeros and return
  return `#${padZero(red)}${padZero(green)}${padZero(blue)}`;
};

/*
  returns the current root host
  ex:
   - if current url = https://capita.workangel.com
    getRootHost() returns 'workangel.com'
   - if current url = http://wadev.dev.hermes.workivate.com
    getRootHost() returns 'workivate.com'
*/

export function getRootHost(hostname = window.location.hostname) {
  return hostname.split('.').reverse().slice(0, 2).reverse().join('.');
}

export function isLifeworksPicture(url) {
  return url.includes('images.lifeworks.com');
}

export function isWorkangelPicture(url) {
  return url.includes('images.workangel.com');
}

function isCloudinaryPicture(url) {
  return url.includes('cloudinary.com');
}

export function hasProtocolDefined(url = '') {
  return url.includes('http');
}

export function processPicture(url, dimensions) {
  return isWorkangelPicture(url) ||
    isLifeworksPicture(url) ||
    isCloudinaryPicture(url)
    ? cloudinary(url, dimensions)
    : url;
}

export function isTouchDevice() {
  return (
    'ontouchstart' in window || 'onmsgesturechange' in window // works on most browsers
  ); // works on ie10
}

export function ellipsify(str, max) {
  return str && str.length > max ? `${str.substr(0, max)}...` : str;
}

let canLogout = true; // use to debounce dispatch(logout)

export const handle401 = async res => {
  if (res.url.includes('cerner-sso')) {
    // in this case the 401 is not coming from our api but from the cerner api
    // the user should NOT be logged out.
    return res;
  }

  const resJSON = await res.json();

  const { code } = resJSON.error || {};

  const sessionInvalidationType = Object.keys(sessionInvalidationTypes).find(
    invalidationType =>
      sessionInvalidationErrorCodes[invalidationType] === code,
  );

  const isAnMFAError =
    code === parseErrorCode(errorCodes['mfa_code_invalid'][0]) ||
    code === parseErrorCode(errorCodes['mfa_session_expired'][0]);

  if (sessionInvalidationType) {
    getStore().dispatch(setIsLoggingOut(true) as Action);
  }

  if (!_isEmpty(getToken()) && !isAnMFAError) {
    // don't dispatch logout too soon after a previous dispatch
    // don't use lodash debounce because we are using 'await'
    // a debounced call won't resolve
    if (canLogout) {
      canLogout = false;

      await getStore().dispatch(
        logout(true, sessionInvalidationType as SessionInvalidationTypes),
      );

      canLogout = true;
    }
  }

  return Promise.reject({ ...resJSON, status: res.status });
};

export function boldify(targettedString, extract) {
  if (!extract) {
    return targettedString;
  }

  const index = targettedString.toLowerCase().indexOf(extract.toLowerCase());

  return index > -1 ? (
    <span>
      {targettedString.slice(0, index)}
      <strong>{targettedString.slice(index, index + extract.length)}</strong>
      {targettedString.slice(index + extract.length, targettedString.length)}
    </span>
  ) : (
    targettedString
  );
}

export function shouldFieldBeDisplayed(editableFields, user, field) {
  // a field should always be displayed if it's editable.
  // if not editable, a field should be displayed if it's not empty. (in this case it should be disabled).
  return (
    editableFields[field] ||
    !(_isUndefined(user[field]) || _isNull(user[field]))
  );
}

export function createAddress(input) {
  const fields = [
    'address_line1',
    'address_line2',
    'address_line3',
    'city',
    'county',
    'postcode',
  ];

  return fields
    .filter(field => input[field] && input[field].length > 0)
    .map(field => input[field])
    .join(', ');
}

export const delay = (milliseconds, value?) => {
  return new Promise(resolve => {
    setTimeout(resolve.bind(null, value), milliseconds);
  });
};

export function getWalletCurrencySymbol() {
  return getSymbolFromCurrency(getCurrencyCode());
}

export function getLocalisedNumericString(number: number) {
  const locale = (Language.getLocale() || 'en_GB').replace('_', '-');

  return new Intl.NumberFormat(locale).format(number);
}

export function getLocalisedCurrencyString(
  amount: number,
  currencyCode = getCurrencyCode(),
  digits = 2,
  longFormat = false,
) {
  const locale = Language.getLocale();
  let currencyString;
  const fractionDigits = !longFormat && amount % 1 === 0 ? 0 : digits;

  try {
    currencyString = new Intl.NumberFormat(locale.replace('_', '-'), {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(amount);
  } catch (e) {
    currencyString = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(amount);
  }

  // In safari it seems to cause interpolation problems when it encounter '$number' ($1)
  // a solution for now is to encode $ sign and add a space after it.
  return currencyString.replace('$', '\u0024 ');
}

export const getLocaleBrandingLogo = (isDark?: boolean) => {
  const telusOne = isDark
    ? require('assets/images/telus-health-one-dark.svg')
    : require('assets/images/telus-health-one.svg');

  const telusSante = isDark
    ? require('assets/images/telus-sante-dark.svg')
    : require('assets/images/telus-sante.svg');

  return Language.getLocale() === 'fr_CA' ? telusSante : telusOne;
};

export const getSmallBrandingLogo = (isDark?: boolean) => {
  return isDark
    ? require('assets/images/flying-t-dark.svg')
    : require('assets/images/flying-t-light.svg');
};

const getCurrencyCode = () => {
  const user = getUser() || {};
  return user.wallet ? user.wallet.currency : 'GBP';
};

export function getFullPrice(value, digits = 2, fromPence = false) {
  if (value || value === 0) {
    const amount = value.amount || value.amount === 0 ? value.amount : value;
    const currency = value.currency ? value.currency : getCurrencyCode();

    return getLocalisedCurrencyString(
      fromPence ? amount / 100 : amount,
      currency,
      digits,
      true,
    );
  }
  return '';
}

export function escapeHtml(str) {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(str));

  return div.innerHTML;
}

export function generateUniqueId(separator) {
  const delim = separator || '-';

  function s4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return (
    s4() +
    s4() +
    delim +
    s4() +
    delim +
    s4() +
    delim +
    s4() +
    delim +
    s4() +
    s4() +
    s4()
  );
}

export function getGroupName(isMobile, isTablet) {
  const characterLimit = isMobile ? 49 : isTablet ? 63 : 98;
  const groupInformations = getStore().getState().session?.user?.group;

  return (groupInformations?.name.length || 0) > characterLimit
    ? `${groupInformations?.name.slice(0, characterLimit - 3)}...`
    : groupInformations?.name;
}

export function hexToRgba(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result && result[1] && result[2] && result[3]
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}, ${opacity})`
    : null;
}

export const numberAbbreviate = (numb, length, truncZero) => {
  if (length < 3) {
    throw new Error('length can not be less then 3');
  }

  if (numb === 0) {
    return numb.toString();
  }

  const abbr = ['K', 'M', 'G', 'T', 'P', 'E'];
  const pow = Math.log10(numb);
  const letter = Math.trunc(pow / 3);
  const fractionalLength = length - Math.ceil(pow - letter * 3);
  const getFinalNumber = () => {
    const finalNumber = (numb / Math.pow(10, letter * 3)).toFixed(
      fractionalLength && letter ? fractionalLength - 1 : 0,
    );

    return truncZero ? Number(finalNumber) : finalNumber;
  };

  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  return getFinalNumber() + (letter ? abbr[letter - 1] : '');
};

// :: (String key, String value) -> String encodedURIComponent
const formatParameter = ([key, value]) => `${key}=${encodeURIComponent(value)}`;
// :: Object -> String
const encodeParams = compose(join('&'), map(formatParameter), toPairs);
// :: String -> {key: value} -> String encodedURIComponent
export const encodeUrl = ramdaUseWith(concat, [
  when(complement(includes('?')), concat(__, '?')),
  encodeParams,
]);

export const removeUserCountryName = telephoneNumbers => {
  const userCountryCode = getUser().country_code;

  return telephoneNumbers.map(telephoneNumber => {
    if (telephoneNumber.country_code === userCountryCode) {
      delete telephoneNumber.country_name;
    }

    return telephoneNumber;
  });
};

export const getEAPPhoneNumbers = user =>
  user['effective_settings'] &&
  user['effective_settings']['services'] &&
  user['effective_settings']['services']['eap_phone_numbers']
    ? removeUserCountryName(
        user['effective_settings']['services']['eap_phone_numbers'],
      )
    : [];

export const metersToUKDistance = meters => {
  if (meters === 0) {
    return false;
  } // no distance return
  const miles = (meters / 1000) * 0.6213712;
  return miles < 0.2 // if under two miles show in yards
    ? `${Math.round(miles * 1760)} yd`
    : `${Math.round(miles * 10) / 10} mi`;
};

// Recursive update of object - to test more deeply
// Support array update with indexes
export const updateIn = (obj, path, val) => {
  if (!path || !path.length || !Array.isArray(path)) return obj;
  const current = path.shift();
  if (Array.isArray(obj)) {
    const newObj = obj.slice();
    newObj[current] =
      path.length === 0 ? val : updateIn(obj.slice()[current], path, val);
    return newObj;
  }
  return Object.assign({}, obj, {
    [current]: path.length === 0 ? val : updateIn(obj[current], path, val),
  });
};

// add params to url as query parameter or as part of the url
// given {test: 1, userId: 1234} and '/url/:userId' will return /url/1234?test=1
export function injectParamsInUrl(url: string, params = {}): string {
  const pathParams = {};
  const queryParams = new URLSearchParams();

  // Separate path params from query params
  Object.entries(params).forEach(([key, value]) => {
    if (new RegExp(`/:${key}(?:[/?]|$)`).test(url)) {
      pathParams[key] = value;
    } else {
      queryParams.append(key, String(value));
    }
  });

  // Generate the path with dynamic params
  const path = generatePath(url, pathParams);

  // Return the full URL including query params if any
  if (queryParams.toString()) {
    const sign = path.includes('?') ? '&' : '?';

    return `${path}${sign}${queryParams.toString()}`;
  } else {
    return path;
  }
}

// true if image size is less then dimension or image is null otherwise false
// dimension is in bytes (int)
export const imageLessThan = curry(
  (dimension: number, image: { file: { size: number } }): boolean => {
    if (image === null) {
      return true;
    }
    return image.file.size < dimension;
  },
);

export const generateActionsTypes = (base, actionsArray) => {
  // converts camelCase to snake_case and then to uppercase
  return actionsArray.reduce(
    (prev, action) => ({
      ...prev,
      [action]: `${base}/${action}`
        .replace(/([A-Z])/g, char => `_${char}`)
        .toUpperCase(),
    }),
    {},
  );
};

export const generatePlainAction = (type, payload) => ({
  type,
  payload: {
    ...payload,
  },
});

// generator to iterate over deep objects
function* iterateObject(obj) {
  for (const key of Object.keys(obj)) {
    yield [key, obj[key]];
  }
}

// produces encoded, php array notation, query string of the form:
// "p1=1&p2=string&arr[0]=1&arr[1]=2&arr[string]=string&deep[0][0]=1&deep[0][1]=1&deep[1][string]=deep"
export function serialize(obj, prefix?) {
  const str: any[] = [];
  for (const [p, v] of iterateObject(obj)) {
    const k = prefix ? `${prefix}[${p}]` : p;
    str.push(
      v !== null && typeof v === 'object'
        ? serialize(v, k)
        : encodeURIComponent(k) + '=' + encodeURIComponent(v),
    );
  }

  return str.join('&');
}

export async function stall(stallTime = 3000) {
  await new Promise(resolve => setTimeout(resolve, stallTime));
}

export const removeFalseyValues = obj => pickBy(obj, identity);

export const scrollTo = (selector, delay: number | undefined = 0, params?) => {
  setTimeout(() => {
    const elem =
      typeof selector === 'string'
        ? document.querySelector(selector)
        : selector;

    window.scrollTo({
      top: elem.offsetTop,
      behavior: 'smooth',
      ...params,
    });
  }, delay);
};

export const deepReplaceValues = (object, searchKeysArray, cb) => {
  const clone = _clone(object);

  each(object, (val, key) => {
    if (searchKeysArray.filter(searchKey => searchKey === key).length > 0) {
      clone[key] = cb(val, object);
    } else if (typeof val === 'object') {
      clone[key] = deepReplaceValues(val, searchKeysArray, cb);
    }
  });

  return clone;
};

/*
  @func keysToSnake
  @param {Object} obj
  @return {Object}

  @desc Will convert the keys of an object to snake_case.
  ex: keysToSnake({ someKey: foo }) // -> { some_key: foo }
*/

export const keysToSnake = (obj: any) =>
  mapKeys(obj, (v: any, k: any) => snakeCase(k));

/*
  @func keysToCamel
  @param {Object} obj
  @return {Object}

  @desc Will convert the keys of an object to camelCase.
  ex: keysToSnake({ some_key: foo }) // -> { someKey: foo }
*/

export const keysToCamel = (obj: any) =>
  mapKeys(obj, (v: any, k: any) => camelCase(k));

/*
  @func filterObjects
  @param {Array} collection
  @param {Array} filters
  @return {Array}

  @desc Will filter objects that match the passed filters.
  filters is an array of arrays. each row will be reduced with AND logic.
  [
    [
      'path.to.prop',                     // can be shallow or deep.
      ['matchValue1', 'matchValue2'],     // values to match against.
      true                                // shouldMatch, if `false` will return
                                          // all values that don't match.
    ],
    [second filter],
    [third filter],
  ]

  ex:
    filterObjects(assessments,
      [
        ['status', ['Completed', 'NotStarted'],
        ['numberOfQuestions', [2], false],
      ]
    )
*/

export const filterObjects = (collection, filters) =>
  collection.filter(element =>
    filters.reduce(
      (keep, [path, inValues, shouldMatch = true]) =>
        keep && shouldMatch === inValues.includes(get(element, path)),
      true,
    ),
  );

export const openAdminPanel = async (path = '') => {
  const redirection = window.open(
    `${window.location.protocol}//${window.location.host}/waiting`,
    '_blank',
  );

  try {
    const oneTimeToken = await replaceWamWithOneTimeToken();
    if (redirection) {
      redirection.location = `${
        window.WAM.ENV.adminPanelUrl
      }${path}?userToken=${oneTimeToken}&subdomain=${
        getCompany().wa_subdomain
      }`;
    }
  } catch (error) {
    redirection?.close();
    getStore().dispatch(
      showToast({
        type: ToastType.ERROR,
        message: polyglot.t('global.error'),
      }),
    );
  }
};

export const prefersReducedMotion = (): boolean => {
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

  if (!mediaQuery || mediaQuery.matches) {
    return true;
  }

  return false;
};

export const getInitials = (firstName: string, lastName: string): string => {
  return (
    firstName.charAt(0).toLocaleUpperCase() +
    lastName.charAt(0).toLocaleUpperCase()
  );
};
