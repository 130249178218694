import { addMinutes } from 'date-fns';

export const setCookie = (name, value, minutes, domain, path = '/') => {
  const cookie: Array<string> = [];
  const expiry = addMinutes(new Date(), minutes);

  cookie.push(`${name}=${value}`);
  cookie.push(`expires=${expiry.toUTCString()}`);
  cookie.push(`domain=${domain}`);
  cookie.push(`path=${path}`);

  if (
    location &&
    location.protocol &&
    location.protocol.indexOf('https') > -1
  ) {
    cookie.push('secure');
  }

  document.cookie = cookie.join('; ');
};

export function getCookie(sKey) {
  if (!sKey) {
    return null;
  }

  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' +
            // eslint-disable-next-line no-useless-escape
            encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
            '\\s*\\=\\s*([^;]*).*$)|^.*$',
        ),
        '$1',
      ),
    ) || null
  );
}

export function deleteCookie(name, domain) {
  const domainField = domain ? 'domain=' + domain : '';
  document.cookie =
    name + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;' + domainField;
}
