/**
 * Authentication helper functions for consuming the shared oauth2 'AuthClient' class
 */
import {
  AuthClient,
  AuthConstants,
  AppLogger,
} from '@workivate/tho-web-shared';
import fetch from 'globals/wa-fetch';
import { lookupKeys, performTenancyLookup } from 'globals/regional-api';
import { setUser } from 'stores/user-store';
import { fetchCompany, setCompany } from 'stores/company-store';
import { updateFeaturesList } from 'state/features-list/actions';
import { getToken } from 'state/session/helpers';
import { getStore } from 'hermes-redux';
import { storageKeys } from 'globals/storage';
import { logout } from 'state/session/actions';
import { isUnifiedLoginEnabled } from 'globals/testable';

/**
 * Checks if a user is authenticated with USL
 * If not, it will register and authorise the user
 * @returns {Promise<void>}
 */
export const authenticateWithUSL = async (): Promise<void> => {
  await AuthClient.checkIfUserIsAuthenticated();
};

/**
 * Initialises the login process
 * Sets user and company into the redux store
 */
export const initialiseLogin = async () => {
  const tenancyHash = localStorage.getItem(lookupKeys.tenancyHash);
  let lookUpResponse;

  if (tenancyHash) {
    lookUpResponse = await performTenancyLookup(
      lookupKeys.tenancyHash,
      tenancyHash,
    );
  }
  if (lookUpResponse) {
    fetch('/user/me?exclude_field=badges', {
      body: undefined,
      method: 'get',
      oauth2token: true,
    }).then(async ({ body: user }) => {
      setUser(user);

      const company = await fetchCompany(user.company_id);
      setCompany(company.body);

      if (user.features_list.length !== 0) {
        getStore().dispatch(updateFeaturesList(user.features_list));
      }
    });
  }
};

/**
 * Gets the authentication header for the current user
 * Can be Wam-Token or Bearer (JWT)
 * If the smartRefresh fails, it will log the user out
 */
export const getAuthenticationHeader = async (): Promise<object> => {
  const header = {};

  if (
    localStorage.getItem(storageKeys.authenticationMethod) ===
      AuthConstants.AUTH_METHODS.oauth2 &&
    isUnifiedLoginEnabled()
  ) {
    try {
      // We need to check with every call how close we are to the token expiration
      // see REFRESH_TOKEN_BUFFER in authClient.ts
      const idToken = await AuthClient.smartRefresh();
      if (idToken) {
        const jwtToken = idToken.getJwtString();
        header['Authorization'] = `Bearer ${jwtToken}`;
      }
    } catch (getJwtTokenError) {
      AppLogger.error('error in getAuthenticationHeader', getJwtTokenError);
      await getStore().dispatch(logout(false));
    }
  } else {
    header['Wam-Token'] = getToken();
  }

  return header;
};

export default authenticateWithUSL;
