import USER_ROLES from 'constants/user-roles.constant';
import { restrictions } from 'state/features-list/constants';

export const createResolver = pageImport =>
  Promise.all([pageImport]).then(([component]) => {
    return component;
  });

const ccsChatModule = {
  name: 'CCSChatMobile',
  url: '/chat/ccs/mobile',
  resolver: () =>
    createResolver(
      import(
        /* webpackChunkName: "ccs-chat-mobile" */ './components/ccs-chat-mobile'
      ),
    ),
  restrictions: {
    user: [restrictions['care_coordination_specialists_messaging']],
  },
  data: {
    title: 'CCS Chat',
    includeInMenu: false,
    hideFooter: true,
    authorizedRoles: [USER_ROLES.employee],
  },
};

export default ccsChatModule;
