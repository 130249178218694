import styles from './select-dropdown.module.scss';
import classNames from 'classnames';
import type { SelectCompValue } from 'globals/types';
import type { Store } from 'globals/perks.types';
import type { CategoryObject } from 'pages/shop-online/state/types';

type Props = {
  id?: string;
  items: SelectCompValue[];
  showList: boolean;
  keyPressed: number | null;
  onItemClick: (item: SelectCompValue) => void;
  selectedItem: SelectCompValue | Store | CategoryObject | null;
};

type State = {
  overItem: number;
};

class SelectDropdown extends React.Component<Props, State> {
  static displayName = 'SelectDropdown';

  state = { overItem: -1 };
  container: {
    current: HTMLDivElement | null;
  };
  dropdownItems: Array<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.container = React.createRef<HTMLDivElement>();
    this.dropdownItems = [];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleKeyPress(nextProps);
  }

  handleKeyPress = nextProps => {
    if (nextProps.keyPressed === 38 && this.state.overItem > 0) {
      this.setState({ overItem: this.state.overItem - 1 });

      if (this.container.current && this.dropdownItems) {
        this.container.current.scrollTop =
          (this.dropdownItems[0]?.getBoundingClientRect().height || 0) *
          (this.state.overItem - 1);
      }
    } else if (
      nextProps.keyPressed === 40 &&
      this.state.overItem < this.props.items.length - 1
    ) {
      this.setState({ overItem: this.state.overItem + 1 });
      if (this.container.current && this.dropdownItems) {
        this.container.current.scrollTop =
          (this.dropdownItems[0]?.getBoundingClientRect().height || 0) *
          this.state.overItem;
      }
    } else if (nextProps.keyPressed === 13 && this.state.overItem > -1) {
      const item = this.props.items[this.state.overItem];

      if (item) {
        this.props.onItemClick(item);
      }
    }
  };

  render() {
    if (this.props.items.length === 0) {
      return null;
    }

    return (
      <div
        className={classNames(
          styles.wrapper,
          this.props.showList && styles['wrapper--visible'],
        )}
        ref={this.container}
      >
        {this.props.items.map((item: SelectCompValue, i) => {
          return (
            <div
              ref={(dropdownItem: HTMLDivElement) =>
                (this.dropdownItems[i] = dropdownItem)
              }
              key={i}
              onMouseDown={e => e.preventDefault()}
              onMouseEnter={() => this.setState({ overItem: i })}
              onMouseLeave={() => this.setState({ overItem: -1 })}
              onClick={() => this.props.onItemClick(item)}
              className={classNames(
                styles.item,
                this.state.overItem === i ||
                  (this.props.selectedItem &&
                    this.props.selectedItem.value === item.value)
                  ? styles['item--active']
                  : styles['item--inactive'],
              )}
              tabIndex={0}
              role='button'
              data-id={`dropdown-item-${i}`}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    );
  }
}

export default SelectDropdown;
